import React from "react";
import { Helmet } from "react-helmet-async";
import { s3baseUrl } from "../../config/config";

export default function HeadController({ data }) {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = s3baseUrl + data.favicon;
  document.head.appendChild(link);

  const meta_desc =
    document.querySelector("meta[name*='description']") ||
    document.createElement("meta");
  meta_desc.content = data.meta_decription;
  document.head.appendChild(meta_desc);

  return (
    <Helmet>
      <title>{data.meta_title}</title>
    </Helmet>
  );
}
