import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import {
  AnnouncementsList,
  Dashboard,
  HolyQuranIndexes,
  HolyQuranIndexMainPage,
  HolyQuranIndexPages,
  LessonBooks,
  Login,
  QuidaEnglishIndexMainPage,
  QuidaEnglishIndexPages,
  StudentsList,
  LoginByAdmin,
  PersonalNotes,
  AnnouncementDetail,
  Chatting,
  TrainingLesson,
  ToDoLists,
  StudentReports,
} from "./pages";

export default function Router() {
  const Authentication = () => {
    if (localStorage.getItem("token")) {
      return <Navigate to="/dashboard"> </Navigate>;
    }
    if (!localStorage.getItem("token")) {
      return <Navigate to="/login"> </Navigate>;
    }
  };

  return (
    <Routes>
      <Route path="/login-by-admin/:token" element={<LoginByAdmin />} />
      <Route element={<DashboardLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/lesson-books" element={<LessonBooks />} />
        <Route path="/lesson-books/quran-pak" element={<HolyQuranIndexes />} />
        <Route
          path="/lesson-books/quran-pak/:index"
          element={<HolyQuranIndexPages />}
        />
        <Route
          path="/lesson-books/quran-pak/:index/:page_index"
          element={<HolyQuranIndexMainPage />}
        />
        <Route
          path="/lesson-books/quida-english"
          element={<QuidaEnglishIndexPages />}
        />
        <Route
          path="/lesson-books/quida-english/:page_index"
          element={<QuidaEnglishIndexMainPage />}
        />
        <Route path="/students" element={<StudentsList />} />
        <Route path="/class-room" element={<Chatting />} />
        <Route path="/class-room/:conversation_id" element={<Chatting />} />
        <Route path="/students-reports" element={<StudentReports />} />
        <Route path="/training-lesson" element={<TrainingLesson />} />
        <Route path="/to-do-lists" element={<ToDoLists />} />
        <Route path="/announcements" element={<AnnouncementsList />} />
        <Route
          path="/announcements/detail/:announcement_id"
          element={<AnnouncementDetail />}
        />
        <Route path="/personal-notes" element={<PersonalNotes />} />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
}
