import { invokeApi } from "../../bl_libs/invokeApi";

export const training_lessons_for_teacher_api = async () => {
  const requestObj = {
    path: `api/training_lesson/training_lessons_for_teacher`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
